// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// import the application.scss we created for the CSS
import "../css/application.scss";

// copy all static images under ../img and ../svg to the output folder,
// and you can reference them with <%= image_pack_tag 'media/img/abc.png' %> or <%= image_pack_tag 'media/svg/def.svg' %>
const images = require.context('../img', true)
const imagePath = (name) => images(name, true)

const svgs = require.context('../svg', true)
const svgPath = (name) => svgs(name, true)

import '@popperjs/core';
import 'xmldoc';

import('../vendor/appear/dist/appear');
import('../vendor/bootstrap/dist/js/bootstrap')
import('../vendor/bootstrap-icons/font/bootstrap-icons.css');

// import('../vendor/chart.js/dist/chart');
// import('../vendor/chart.js/helpers/helpers');
// import('../vendor/chartjs-adapter-date-fns/dist/chartjs-adapter-date-fns');
// import('../vendor/chartjs-chart-matrix/dist/chartjs-chart-matrix');
// import('../vendor/chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels');

import('../vendor/circles.js/circles');
import('../vendor/jquery/dist/jquery.min');

import('../vendor/clipboard/dist/clipboard');
import('../vendor/datatables/media/js/jquery.dataTables');
import('../vendor/datatables.net-buttons/js/dataTables.buttons');
import('../vendor/datatables.net.extensions/dataTables.fixedColumns.min');
import('../vendor/datatables.net.extensions/dataTables.scroller.min.css');
import('../vendor/datatables.net.extensions/fixedColumns.dataTables.min.css');
import('../vendor/datatables.net.extensions/select/select.min');
import('../vendor/datatables.net.extensions/select/select.min.css');
import('../vendor/daterangepicker/daterangepicker');
import('../vendor/daterangepicker/daterangepicker.css');
import('../vendor/dropzone/dist/dropzone');
import('../vendor/dropzone/dist/dropzone.css');
import('../vendor/dropzone/dist/basic.css');
import('../vendor/flag-icon-css/css/flag-icons.min.css');
import('../vendor/flatpickr/dist/flatpickr.min');
import('../vendor/flatpickr/dist/flatpickr.min.css');
import('../vendor/fslightbox/index');
import('../vendor/fullcalendar/main.min');
import('../vendor/fullcalendar/main.min.css');
import('../vendor/hs-add-field/dist/hs-add-field.min');
import('../vendor/hs-count-characters/dist/js/hs-count-characters.min');
import('../vendor/hs-counter/dist/hs-counter.min');
import('../vendor/hs-file-attach/dist/hs-file-attach.min');
import('../vendor/hs-form-search/dist/hs-form-search.min');
import('../vendor/hs-fullcalendar-filter/dist/hs-fullcalendar-filter.min');
import('../vendor/hs-fullscreen/dist/hs-fullscreen.min');
import('../vendor/hs-fullscreen/dist/hs-fullscreen.min.css');
import('../vendor/hs-go-to/dist/hs-go-to.min');
import('../vendor/hs-header/dist/hs-header.min');
import('../vendor/hs-header/dist/hs-navbar.min');
import('../vendor/hs-img-compare/hs-img-compare');
import('../vendor/hs-img-compare/hs-img-compare.css');
import('../vendor/hs-loading-state/dist/hs-loading-state.min');
import('../vendor/hs-loading-state/dist/hs-loading-state.min.css');
import('../vendor/hs-mega-menu/dist/hs-mega-menu.min');
import('../vendor/hs-mega-menu/dist/hs-mega-menu.min.css');
import('../vendor/hs-nav-scroller/dist/hs-nav-scroller.min');
import('../vendor/hs-navbar-vertical-aside/dist/hs-navbar-vertical-aside.min');
import('../vendor/hs-navbar-vertical-aside/dist/hs-navbar-vertical-aside-mini-cache');
import('../vendor/hs-quantity-counter/dist/hs-quantity-counter.min');
import('../vendor/hs-remove-element/dist/hs-remove-element.min');
import('../vendor/hs-scrollspy/dist/hs-scrollspy.min');
import('../vendor/hs-step-form/dist/hs-step-form.min');
import('../vendor/hs-sticky-block/dist/hs-sticky-block.min');
import('../vendor/hs-table-sticky-header/dist/hs-table-sticky-header.min');
import('../vendor/hs-toggle-password/dist/hs-toggle-password.min');
import('../vendor/hs-toggle-switch/dist/hs-toggle-switch.min');
import('../vendor/imask/dist/imask.min');
import('../vendor/jquery-migrate/dist/jquery-migrate.min');
import('../vendor/jsvectormap/dist/js/jsvectormap.min');
import('../vendor/jsvectormap/dist/css/jsvectormap.min.css');
import('../vendor/jszip/dist/jszip.min');
import('../vendor/leaflet/dist/leaflet-src');
import('../vendor/leaflet/dist/leaflet.css');
import('../vendor/list.js/dist/list.min');

// import('../vendor/pdfmake/src/printer');

import('../vendor/prism/prism');
import('../vendor/prism/prism.css');
import('../vendor/quill/dist/quill.min');
import('../vendor/quill/dist/quill.core.css');
import('../vendor/sortablejs/Sortable.min');
import('../vendor/tom-select/dist/js/tom-select.complete.min');
import('../vendor/tom-select/dist/css/tom-select.min.css')

import('../src/theme-custom');
import('../src/theme.min');
import('../src/hs.theme-appearance');
import('../src/hs.theme-appearance-charts');

Rails.start()
ActiveStorage.start()

// export jquery to global
var jQuery = require('jquery')

var datePicker = require('../vendor/daterangepicker/daterangepicker')

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually referring to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

global.datePicker = global.datePicker = datePicker;
window.datePicker = window.datePicker = datePicker;
